<template>
	<div class="goods-detail">
		<div class="preview-wrap">
			<div class="video-player-wrap" :class="{ show: switchMedia == 'video' }" v-if="goodsSkuDetail.video_url != ''">
				<video-player v-if="goodsSkuDetail.video_url != ''" ref="videoPlayer" :playsinline="true" :options="playerOptions"
				 @play="onPlayerPlay($event)" @pause="onPlayerPause($event)" @ended="onPlayerEnded($event)" @waiting="onPlayerWaiting($event)"
				 @playing="onPlayerPlaying($event)" @loadeddata="onPlayerLoadeddata($event)" @timeupdate="onPlayerTimeupdate($event)"
				 @canplay="onPlayerCanplay($event)" @canplaythrough="onPlayerCanplaythrough($event)" @statechanged="playerStateChanged($event)"
				 @ready="playerReadied"></video-player>

				<div class="media-mode" v-if="goodsSkuDetail.video_url != ''">
					<span :class="{ 'ns-bg-color': switchMedia == 'video' }" @click="switchMedia = 'video'">{{$lang('view.promotion.seckill.detail.detail12')}}</span>
					<span :class="{ 'ns-bg-color': switchMedia == 'img' }" @click="switchMedia = 'img'">{{$lang('view.promotion.seckill.detail.detail13')}}</span>
				</div>
			</div>
			<div class="magnifier-wrap">
				<pic-zoom ref="PicZoom" :url="$img(picZoomUrl)" :scale="2"></pic-zoom>
			</div>

			<div class="spec-items">
				<span class="left-btn iconfont iconarrow-left-copy" :class="{ move: moveThumbLeft }" @click="changeThumbImg('prev')"></span>
				<span class="right-btn iconfont iconarrow-right" :class="{ move: moveThumbRight }" @click="changeThumbImg('next')"></span>
				<ul :style="{ left: 30 + thumbPosition + 'px' }">
					<!-- 商品缩率图 -->
					<li v-for="(item, index) in goodsSkuDetail.sku_images" :key="index" @mousemove="picZoomUrl = item" :class="{ selected: picZoomUrl == item }">
						<img :src="$img(item, { size: 'small' })" @error="imageErrorSpec(index)" />
					</li>
				</ul>
			</div>

			<div class="share-collect">
				<div @click="editCollection">
					<i class="iconfont" :class="whetherCollection == 1 ? 'iconlikefill ns-text-color' : 'iconlike'"></i>
					<span data-collects="0" class="focus-text">{{$lang('view.promotion.seckill.detail.detail14')}}（{{ goodsSkuDetail.collect_num }}）</span></div>
				<div @click="service_link">
					<i class="iconfont iconzhanghao"></i>
					<span data-collects="0">{{$lang('view.promotion.seckill.detail.detail15')}}</span>
				</div>

			</div>
		</div>

		<!-- 商品信息 -->
		<div class="basic-info-wrap" v-loading="loading">
			<h1>{{ goodsSkuDetail.sku_name }}</h1>
			<p class="desc ns-text-color" v-if="goodsSkuDetail.introduction">{{ goodsSkuDetail.introduction }}</p>

			<div class="discount-banner ns-bg-color" v-if="seckillTimeMachine.currentTime">
				<div class="activity-name"><i class="discount-icon iconfont iconicon_naozhong"></i><span>{{$lang('view.promotion.seckill.detail.detail16')}}</span></div>
				<div class="surplus-time">
					<span>{{ seckillText }}</span>
					<count-down class="count-down" v-on:start_callback="countDownS_cb()" v-on:end_callback="countDownE_cb()"
					 :currentTime="seckillTimeMachine.currentTime" :startTime="seckillTimeMachine.startTime" :endTime="seckillTimeMachine.endTime"
					 :dayTxt="$lang('view.promotion.seckill.detail.detail17')" :hourTxt="$lang('view.promotion.seckill.detail.detail18')" :minutesTxt="$lang('view.promotion.seckill.detail.detail19')" :secondsTxt="$lang('view.promotion.seckill.detail.detail20')"></count-down>
				</div>
			</div>

			<div class="item-block">
				<div class="promotion-price">
					<dl class="item-line">
						<dt class="ns-text-color-gray">{{$lang('view.promotion.seckill.detail.detail21')}}</dt>
						<dd>
							<em class="yuan ns-text-color">Ks </em>
							<span class="price ns-text-color">{{ goodsSkuDetail.seckill_price }}</span>
						</dd>
					</dl>
					<dl class="item-line">
						<dt class="ns-text-color-gray">{{$lang('view.promotion.seckill.detail.detail22')}}</dt>
						<dd>
							<em class="market-yuan">Ks </em>
							<span class="market-price">{{ goodsSkuDetail.price }}</span>
						</dd>
					</dl>

					<div class="statistical">
						<ul>
							<li>
								<p>{{$lang('view.promotion.seckill.detail.detail23')}}</p>
								<span>{{ goodsSkuDetail.evaluate }}</span>
							</li>
							<li>
								<p>{{$lang('view.promotion.seckill.detail.detail24')}}</p>
								<span>{{ goodsSkuDetail.sale_num }}{{ goodsSkuDetail.unit }}</span>
							</li>
						</ul>
					</div>

					<dl class="item-line" v-if="goodsSkuDetail.is_virtual == 0">
						<dt>{{$lang('view.promotion.seckill.detail.detail25')}}</dt>
						<dd>
							<i class="i-activity-flag ns-text-color ns-border-color" v-if="goodsSkuDetail.is_free_shipping">{{$lang('view.promotion.seckill.detail.detail26')}}</i>
							<i class="i-activity-flag ns-text-color ns-border-color" v-else>{{$lang('view.promotion.seckill.detail.detail27')}}</i>
						</dd>
					</dl>
				</div>
			</div>
			<dl class="item-line delivery" v-if="goodsSkuDetail.is_virtual == 0">
				<dt>{{$lang('view.promotion.seckill.detail.detail28')}}</dt>
				<dd>
					<div class="region-selected ns-border-color-gray">
						<span>
							<template v-if="selectedAddress['level_1']">
								<template v-for="item in selectedAddress">
									{{ item.name }}
								</template>
							</template>
							<template v-else>
								{{$lang('view.promotion.seckill.detail.detail29')}}
							</template>
						</span>
						<i class="el-icon-arrow-down"></i>
					</div>

					<div class="region-list ns-border-color-gray" :class="{ hide: hideRegion }">
						<ul class="nav-tabs">
							<li :class="{ active: currTabAddres == 'province' }" @click="currTabAddres = 'province'">
								<div>
									<span>{{ selectedAddress['level_1'] ? selectedAddress['level_1'].name : $lang('view.promotion.seckill.detail.detail30') }}</span>
									<i class="el-icon-arrow-down"></i>
								</div>
							</li>
							<li :class="{ active: currTabAddres == 'city' }" @click="currTabAddres = 'city'">
								<div>
									<span>{{ selectedAddress['level_2'] ? selectedAddress['level_2'].name : $lang('view.promotion.seckill.detail.detail31') }}</span>
									<i class="el-icon-arrow-down"></i>
								</div>
							</li>
							<li :class="{ active: currTabAddres == 'district' }" @click="currTabAddres = 'district'">
								<div>
									<span>{{ selectedAddress['level_3'] ? selectedAddress['level_3'].name : $lang('view.promotion.seckill.detail.detail32') }}</span>
									<i class="el-icon-arrow-down"></i>
								</div>
							</li>
						</ul>
						<div class="tab-content">
							<div class="tab-pane" :class="{ active: currTabAddres == 'province' }">
								<ul class="province">
									<li v-for="(item, index) in provinceArr" :key="index" :class="{ selected: selectedAddress['level_' + item.level] && selectedAddress['level_' + item.level].id == item.id }">
										<span @click="getAddress('city', item)">{{ item.name }}</span>
									</li>
								</ul>
							</div>
							<div class="tab-pane" :class="{ active: currTabAddres == 'city' }">
								<ul class="city">
									<li v-for="(item, index) in cityArr" :key="index" :class="{ selected: selectedAddress['level_' + item.level] && selectedAddress['level_' + item.level].id == item.id }">
										<span @click="getAddress('district', item)">{{ item.name }}</span>
									</li>
								</ul>
							</div>
							<div class="tab-pane" :class="{ active: currTabAddres == 'district' }">
								<ul class="district">
									<li v-for="(item, index) in districtArr" :key="index" :class="{ selected: selectedAddress['level_' + item.level] && selectedAddress['level_' + item.level].id == item.id }">
										<span @click="getAddress('community', item)">{{ item.name }}</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</dd>
			</dl>
            <dl class="item-line service">
                <dt>{{$lang('view.promotion.seckill.detail.detail33')}}</dt>
                <dd>
                    <span>
                        
                        <router-link :to="'/shop-' + shopInfo.site_id" class="ns-text-color">{{ shopInfo.site_name }}</router-link>
                        {{$lang('view.promotion.seckill.detail.detail34')}}
                    </span>
                </dd>
            </dl>
            <hr class="divider" />
            <div class="sku-list" v-if="goodsSkuDetail.goods_spec_format">
                <dl class="item-line" v-for="(item, index) in goodsSkuDetail.goods_spec_format" :key="index">
                    <dt>{{ item.spec_name }}</dt>
                    <dd>
                        <ul>
                            <li v-for="(item_value, index_value) in item.value" :key="index_value">
                                <div
                                    :class="{
                                        'selected ns-border-color': item_value['selected'] || skuId == item_value.sku_id,
                                        disabled: item_value['disabled'] || (!item_value['selected'] && item_value['disabled'])
                                    }"
								 @click="changeSpec(item_value.sku_id, item_value.spec_id)">
									<img v-if="item_value.image" :src="$img(item_value.image, { size: 'small' })" />
									<span>{{ item_value.spec_value_name }}</span>
									<i class="iconfont iconduigou1 ns-text-color"></i>
								</div>
							</li>
						</ul>
					</dd>
				</dl>
			</div>

			<div class="buy-number">
				<dl class="item-line">
					<dt>{{$lang('view.promotion.seckill.detail.detail35')}}</dt>
					<dd>
						<div class="num-wrap">
							<el-input v-model="number" placeholder="0" @input="keyInput(false)" @blur="blur"></el-input>
							<div class="operation">
								<span class="increase el-icon-caret-top" @click="changeNum('+')"></span>
								<span class="decrease el-icon-caret-bottom" @click="changeNum('-')"></span>
							</div>
						</div>
						<span class="unit">{{ goodsSkuDetail.unit }}</span>
						<span class="inventory">{{$lang('view.promotion.seckill.detail.detail36')}}{{ goodsSkuDetail.stock }}{{ goodsSkuDetail.unit }}</span>
					</dd>
				</dl>
			</div>

			<dl class="item-line buy-btn">
				<dt></dt>
				<dd>
					<template v-if="goodsSkuDetail.goods_state == 1 && goodsSkuDetail.verify_state == 1">
						<el-button type="primary" plain @click="buyNow">{{$lang('view.promotion.seckill.detail.detail37')}}</el-button>
					</template>
					<template v-else>
						<el-button type="info" plain disabled>{{$lang('view.promotion.seckill.detail.detail38')}}</el-button>
					</template>
					<div href="javascript:;" class="go-phone">
						<img src="@/assets/images/goods/qrcode.png" />
						<div class="qrcode-wrap"><img :src="qrcode" alt="二维码图片" /></div>
					</div>
				</dd>
			</dl>

			<dl class="item-line merchant-service" v-show="shopInfo.shop_baozh == 1 || shopInfo.shop_qtian == 1 || shopInfo.shop_zhping == 1 || shopInfo.shop_erxiaoshi == 1 || shopInfo.shop_tuihuo == 1 || shopInfo.shop_shiyong == 1 || shopInfo.shop_shiti == 1 || shopInfo.shop_xiaoxie == 1">
				<dt>{{$lang('view.promotion.seckill.detail.detail39')}}</dt>
				<div>
					<dd v-if="shopInfo.shop_baozh == 1">
						<i class="el-icon-success"></i>
						<span class="ns-text-color-gray" :title="$lang('view.promotion.seckill.detail.detail40')">{{$lang('view.promotion.seckill.detail.detail40')}}</span>
					</dd>
					<dd v-if="shopInfo.shop_qtian == 1">
						<i class="el-icon-success"></i>
						<span class="ns-text-color-gray" :title="$lang('view.promotion.seckill.detail.detail41')">{{$lang('view.promotion.seckill.detail.detail42')}}</span>
					</dd>
					<dd v-if="shopInfo.shop_zhping == 1">
						<i class="el-icon-success"></i>
						<span class="ns-text-color-gray" :title="$lang('view.promotion.seckill.detail.detail43')">{{$lang('view.promotion.seckill.detail.detail43')}}</span>
					</dd>
					<dd v-if="shopInfo.shop_erxiaoshi == 1">
						<i class="el-icon-success"></i>
						<span class="ns-text-color-gray" :title="$lang('view.promotion.seckill.detail.detail44')">{{$lang('view.promotion.seckill.detail.detail44')}}</span>
					</dd>
					<dd v-if="shopInfo.shop_tuihuo == 1">
						<i class="el-icon-success"></i>
						<span class="ns-text-color-gray" :title="$lang('view.promotion.seckill.detail.detail45')">{{$lang('view.promotion.seckill.detail.detail45')}}</span>
					</dd>
					<dd v-if="shopInfo.shop_shiyong == 1">
						<i class="el-icon-success"></i>
						<span class="ns-text-color-gray" :title="$lang('view.promotion.seckill.detail.detail46')">{{$lang('view.promotion.seckill.detail.detail46')}}</span>
					</dd>
					<dd v-if="shopInfo.shop_shiti == 1">
						<i class="el-icon-success"></i>
						<span class="ns-text-color-gray" :title="$lang('view.promotion.seckill.detail.detail47')">{{$lang('view.promotion.seckill.detail.detail47')}}</span>
					</dd>
					<dd v-if="shopInfo.shop_xiaoxie == 1">
						<i class="el-icon-success"></i>
						<span class="ns-text-color-gray" :title="$lang('view.promotion.seckill.detail.detail48')">{{$lang('view.promotion.seckill.detail.detail49')}}</span>
					</dd>
				</div>
			</dl>
		</div>

		<!-- 店铺信息 -->
		<div class="shop-wrap">
			<div class="head-wrap">
				<div class="img-wrap">
					<img class="img-responsive center-block" :src="shopInfo.avatar ? $img(shopInfo.avatar) : $img(defaultShopImage)"
					 @error="shopInfo.avatar = defaultShopImage" :alt="shopInfo.site_name" />
				</div>
				<h5>
					<span class="site-name">{{ shopInfo.site_name }}</span>
					<el-tag class="tag" size="small" v-if="shopInfo.is_own == 1">{{$lang('view.promotion.seckill.detail.detail50')}}</el-tag>
				</h5>
			</div>
			<div class="info-wrap">
				<dl>
					<dt class="site-score">{{$lang('view.promotion.seckill.detail.detail51')}}</dt>
					<dd>
						<el-rate v-model="score" disabled></el-rate>
					</dd>
					<dt>{{$lang('view.promotion.seckill.detail.detail52')}}：</dt>
					<dd><span>{{ shopInfo.shop_desccredit }}</span>{{$lang('view.promotion.seckill.detail.detail55')}}</dd>
					<dt>{{$lang('view.promotion.seckill.detail.detail53')}}：</dt>
					<dd><span>{{ shopInfo.shop_servicecredit }}</span>{{$lang('view.promotion.seckill.detail.detail55')}}</dd>
					<dt>{{$lang('view.promotion.seckill.detail.detail54')}}：</dt>
					<dd><span>{{ shopInfo.shop_deliverycredit }}</span>{{$lang('view.promotion.seckill.detail.detail55')}}</dd>
				</dl>
			</div>
			<div class="info-wrap" v-if="shopInfo.telephone">
				<dl>
					<dt>{{$lang('view.promotion.seckill.detail.detail56')}}：</dt>
					<dd>{{ shopInfo.telephone }}</dd>
				</dl>
			</div>
			<div class="operation">
				<el-button class="btn btn-default" size="medium" @click="$router.pushToTab('/shop-' + shopInfo.site_id)">进店逛逛</el-button>
				<el-button size="medium" @click="follow" v-if="hasFollow">{{$lang('view.promotion.seckill.detail.detail58')}}</el-button>
				<el-button size="medium" @click="follow" v-else>{{$lang('view.promotion.seckill.detail.detail59')}}</el-button>
			</div>
		</div>

		<div class="detail-wrap">
			<div class="goods-recommended">
				<goods-recommend />
			</div>

			<el-tabs class="goods-tab" v-model="tabName" type="card" @tab-click="tabChange">
				<el-tab-pane :label="$lang('view.promotion.seckill.detail.detail60')" name="detail">
					<div v-html="goodsSkuDetail.goods_content"></div>
				</el-tab-pane>
				<el-tab-pane :label="$lang('view.promotion.seckill.detail.detail61')" name="attr">
					<ul class="attr-list">
						<template v-if="goodsSkuDetail.goods_attr_format && goodsSkuDetail.goods_attr_format.length > 0">
							<li v-for="(item, index) in goodsSkuDetail.goods_attr_format" :key="index">{{ item.attr_name }}：{{ item.attr_value_name }}</li>
						</template>
					</ul>
				</el-tab-pane>
				<el-tab-pane :label="goodsSkuDetail.evaluate ? $lang('view.promotion.seckill.detail.detail62')+'(' + goodsSkuDetail.evaluate + ')' : $lang('view.promotion.seckill.detail.detail62')" name="evaluate"
				 class="evaluate">
					<template v-if="goodsEvaluateList.length">
						<nav>
							<li class="selected">{{$lang('view.promotion.seckill.detail.detail63')}}</li>
							<!-- <li>好评</li>
							<li>中评</li>
							<li>差评</li> -->
						</nav>
						<ul class="list">
							<li v-for="(item, index) in goodsEvaluateList" :key="index">
								<div class="member-info">
									<img :src="$img(item.member_headimg)" @error="imageErrorEvaluate(index)" class="avatar" />
									<span>{{ item.member_name }}</span>
								</div>
								<div class="info-wrap">
									<el-rate v-model="item.star" disabled></el-rate>
									<p class="content">{{ item.content }}</p>
									<div class="img-list" v-if="item.images">
										<el-image v-for="(img, img_index) in item.images" :key="img_index" :src="$img(img)" :preview-src-list="item.imagesFormat"></el-image>
									</div>
									<div class="sku-info">
										<span>{{ item.sku_name }}</span>
										<span class="create-time">{{ $util.timeStampTurnTime(item.create_time) }}</span>
									</div>
									<div class="evaluation-reply" v-if="item.explain_first != ''">{{$lang('view.promotion.seckill.detail.detail64')}}：{{ item.explain_first }}</div>
									<template v-if="item.again_content != ''">
										<div class="review-evaluation">
											<span>{{$lang('view.promotion.seckill.detail.detail65')}}</span>
											<span class="review-time">{{ $util.timeStampTurnTime(item.again_time) }}</span>
										</div>
										<p class="content">{{ item.again_content }}</p>
										<div class="img-list">
											<el-image v-for="(again_img, again_index) in item.again_images" :key="again_index" :src="$img(again_img)"
											 :preview-src-list="item.againImagesFormat"></el-image>
										</div>
										<div class="evaluation-reply" v-if="item.again_explain != ''">{{$lang('view.promotion.seckill.detail.detail66')}}：{{ item.again_explain }}</div>
									</template>
								</div>
							</li>
						</ul>
						<div class="pager">
							<el-pagination background :pager-count="5" :total="total" :prev-text="$lang('view.promotion.seckill.detail.detail67')" :next-text="$lang('view.promotion.seckill.detail.detail68')" :current-page.sync="currentPage"
							 :page-size.sync="pageSize" @size-change="handlePageSizeChange" @current-change="handleCurrentPageChange"
							 hide-on-single-page></el-pagination>
						</div>
					</template>
					<div class="empty" v-else>{{$lang('view.promotion.seckill.detail.detail69')}}</div>
				</el-tab-pane>
				<!-- <el-tab-pane label="售后保障" name="after_sale" class="after-sale"><div v-html="service"></div></el-tab-pane> -->
			</el-tabs>
		</div>
		<!--联系客服弹窗-->
		<servicerMessage ref="servicerMessage" class="kefu" :shop="{shop_id:shopInfo.site_id,logo:shopInfo.logo,shop_name:shopInfo.site_name}"></servicerMessage>
	</div>
</template>

<script>
	import PicZoom from "vue-piczoom"
	import detail from "./detail_seckill.js"
	import GoodsRecommend from "@/components/GoodsRecommend"
	import servicerMessage from "@/components/message/servicerMessage";
	export default {
		name: "seckill_detail",
		components: {
			PicZoom,
			GoodsRecommend,
			servicerMessage
		},
		mixins: [detail]
	}
</script>
<style lang="scss">
	@import "./detail_seckill.scss";
</style>
